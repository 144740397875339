import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import Slider from '@mui/material/Slider';
import FadeIn from 'react-fade-in';
import ProTip from './ProTip'; 
import validator from 'email-validator';
import moment from 'moment';
import confetti from "canvas-confetti";
import { Button, TextField, FormGroup, CardContent, CardActions, Input, Fade} from '@mui/material';
import TextTransition, { presets } from "react-text-transition";
import { useState } from 'react';
import { data } from 'autoprefixer'; 

const theme = createTheme({
  status: {
    danger: '#e53e3e',
  },
  
  palette: {
    secondary: {
      // This is green.A700 as hex.
      main: '#000000',
    },
    primary: {
      main: '#FFFFFF', 
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
  },
  
});
declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: React.CSSProperties['color'];
    };
  }

  interface Palette {
    neutral: Palette['primary'];
  }
  interface PaletteOptions {
    neutral: PaletteOptions['primary'];
  }

  interface PaletteColor {
    darker?: string;
  }
  interface SimplePaletteColorOptions {
    darker?: string;
  }
  interface ThemeOptions {
    status: {
      danger: React.CSSProperties['color'];
    };
  }
}
 

function HomePage(){
  const TEXTS = [ 
    "warranty",
    "extended warranty",
    "shipping coverage"
  ];

  const [index, setIndex] = React.useState(0);
  
  const [emailSignup, setEmailSignup] = useState(null)
  const [emailSignupErr, setEmailSignupErr] = useState(null)
  const [emailSignupSuccess, setEmailSignupSucess] = useState(null)

  React.useEffect(() => {
    const intervalId = setInterval(() =>
      setIndex(index => index + 1),
      3000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return(
<div className="grid  grid-cols-1 xl:grid-cols-2 gap-0">
  <div className="container pt-13 xl:pt-24 text-3xl md:text-5xl font-bold">
  Offer <u>your own</u> warranty and extended warranty policies on your Shopify store.

        <div className='pt-8 pb-16'>Earn <u>thousands</u> in additional revenue.</div>
        {!emailSignupSuccess ?
        <FormGroup row>
 
        {/* <TextField
          label="Join the waitlist"
          placeholder='hi@email.com' 
          value={emailSignup}
          onChange={(x) => {setEmailSignup(x.target.value)}}
          size="medium"
          error={emailSignupErr}
        />  */}
        
        
        <Button style={{'textTransform': "none"}} className={"text-md bg-black text-white font-sans font-bold full-width"} variant="contained"
        onClick={() => { 
          window.open("https://apps.shopify.com/weclaim-warranty-upsells")
        }} fullWidth>
          
Check us out on the Shopify App Store
             </Button>
 
        </FormGroup>
                     : <p>Thank you. We'll let you know when WeClaim launches.</p>
                    }
    </div> 
  <div className="container flex h-fit md:px-16 pt-16">
     <FadeIn>  
      <img className="w-full xl:w-3/4 md:ml-56 w-64 " src="/sneaker.png"></img> 
      
  </FadeIn>
  <div className="absolute scale-50 md:w-64 w-52 font-serif">
  <div id="policy-list" style={{"boxShadow": "0 5px 30px #000000"}} className="policy-list"><div style={{"fontSize": "25px"}} className="policy__header">
    <b style={{"fontSize": "20px"}}> Protect Your New Shoes</b>
    </div>
        <div className="policy__type" id="policy_type__Product_Insurance">
        <div className="policy__type__text">Shipping Coverage</div>
        
        
        <label className="policy">
          <div className="policy__offer">
          <div style={{"fontSize": "20px"}} className="policy__text">Sole Protector</div>
          <div id="policy__price__l07gafx1y55l20qr1vr" className="policy__price"><b>$5</b></div>
          </div>
          <div id="policy__question__l07gafx1y55l20qr1vr" className="policy__question">What does this cover?</div>
        </label>
        </div>
        
        <div className="policy__type" id="policy_type__Extended_Warranty">
        <div className="policy__type__text">Extended Warranty</div>
        
        
        <label className="policy">
          <div className="policy__offer">
          <div style={{"fontSize": "20px"}} className="policy__text">Spill Free</div>
          <div id="policy__price__l07gc0vyygelpshkxr" className="policy__price"><b>$55</b></div>
          </div>
          <div id="policy__question__l07gc0vyygelpshkxr" className="policy__question">What does this cover?</div>
        </label>
        </div>
        
        <div style={{"paddingTop": "10px", "textAlign": "center", "fontSize": "15px"}}>
          Warranties are offered by our shop
          </div> 
        </div> 
    
        </div>  


  
  </div> 
 

  
</div>

 

  )
}

export default function App() {

   
  const [currentPage, setCurrentPage] = React.useState('claim')
 
  return ( 
<ThemeProvider theme={theme}>

  <body className='h-fit'>
<div className="md:p-12 p-6 bg-right bg-cover font-base">
 
	<div className="w-full bg-[#fbeee6] container mx-auto px-8">
			
		<div className="w-full py-5 flex items-center justify-between">
			<a className="flex text-black items-center no-underline hover:no-underline font-bold text-3xl lg:text-5xl"> 
				 WeClaim
      </a>
			
			<div className="flex w-1/2 justify-end content-center">		
 
				<div className="h-10 p-2 md:h-auto md:p-4 ">
       <button className="bg-white text-xl font-base hover:bg-indigo-50 text-black font-bold py-2 px-4 rounded" color="primary" onClick={() => {
         document.location.href = "https://claims.weclaim.app";
          
          }} >File a Claim</button>
          
 
        </div>
        
			</div>
      
			
		</div>
    

	</div>
  <div className="w-full bg-white container mx-auto py-8">
    <HomePage /> 
 <div className="w-full pt-16 text-sm text-center md:text-left fade-in">
      <a className="text-black no-underline text-center " href="#">&copy; WeClaim 2024</a> 
 		</div> 
  
     <footer className="bg-[#81592e] text-white mt-20 text-center lg:text-left">

     <div className="container p-6"> 
      <p className="flex justify-center items-center">
        <span className="mr-4" onClick={() => {window.open("https://docs.google.com/document/d/16Qstd6keabO2L_2YqoRvloRsh4Z38JjY/edit?usp=sharing&ouid=113140434570485063634&rtpof=true&sd=true")}}><u>Privacy Policy</u></span>
        <span className="mr-4" onClick={() => {window.open("https://docs.google.com/document/d/16FdZJNGyJyC5AOc1wxPhZ_u4sAx8RQcG/edit?usp=sharing&ouid=113140434570485063634&rtpof=true&sd=true")}}><u>Terms of Service</u></span>

      </p>
  </div>
 
</footer>
 </div>
 
 
 </div>
 
 
 
 
</body>
</ThemeProvider>
  );
}
